.bookNowContainer {
    margin: 40px;
    padding-top: 100px
}

.bookNowTitle {
    margin: 40px 50px;
    font-size: 40px;
    font-family: Lato;
}

.bookNowCoverImage {
 width: 100%;
 object-fit: cover;
 pointer-events: none
}

.booknowTextContainer {
    margin: 20px 50px;
    width: 70%
}

@media only screen and (max-width: 550px) {
    .booknowTextContainer {
        margin: 0px;
        width: 100%
    }
    .bookNowContainer {
        margin: 15px 20px;
        padding-top: 100px
    }
    .bookNowTitle {
        margin: 15px 0px;
        font-size:28px;
    }
}