.footerContainer {
    background-color: #e6e6e6;
    padding: 10px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%
}

.footerCompanyNameText {
    font-size: 15px;
    font-family: Lato;
}

@media only screen and (max-width: 550px) {
    .footerCompanyNameText {
        font-size: 12px;
        max-width: 60%;
    }
    .footerContainer {
        padding: 25px 20px;
    }
}