.gridGalleryDetailContainer {
    padding: 10px;
}

.GalleryDetailContainer {
    padding-top: 100px;
    margin: 10px 10%
}

.galleryDetailMedia {
    width: 100%;
    object-fit: cover;
    pointer-events: none;
}

@media only screen and (min-width: 550px) and (max-width: 720px) {
    .GalleryDetailContainer {
        padding-top: 110px;
        margin: 10px 5%
    }
    }

  @media only screen and (min-width: 720px) and (max-width: 1024px) {
    .GalleryDetailContainer {
        padding-top: 110px;
        margin: 10px 2%
    }
  }
    
@media only screen and (max-width: 550px) {
    .GalleryDetailContainer {
        padding-top: 110px;
        margin: 7px 5px
    }
}