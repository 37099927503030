.CarouselImage {
    width: 100%;
    object-fit: cover;
    pointer-events: none;
}

.CarouselContainer {
 margin: 10px 15%;
}

.GridContainer {
  margin: 5%;
}

.youtube-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
  }
  
  .youtube-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .YoutubeContainer {
    position: relative;
    /* margin: 20px 15%; */
    justify-content: center;
    align-items: center;
  }

  .GalleryContainer {
    background-color: #e6e6e6;
    margin: 5% 0px;
    padding: 60px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .WeddingFilmsContainer {
    margin: 4% 7%;
  }

  .WeddingFilmsTextContainer {
    margin: 5%;
  }

  
  @media only screen and (min-width: 550px) and (max-width: 720px) {
    .CarouselContainer {
      margin: 10px 5%;
     }
    }

  @media only screen and (min-width: 720px) and (max-width: 1024px) {
    .CarouselContainer {
      margin: 10px 7%;
     }
  }
    
@media only screen and (max-width: 550px) {
  .CarouselContainer {
    margin: 10px 15px;
   }

   .GridContainer {
    margin: 12%;
  }

  .GalleryContainer {
    margin: 10px 0px;
    padding: 30px 30px;
  }

  .WeddingFilmsTextContainer {
    margin: 40px;
  }
}