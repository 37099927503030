.aboutUsContainer {
    margin: 20px 100px;
    padding-top: 100px
}

@media only screen and (max-width: 550px) {
    .aboutUsContainer {
        padding-top: 80px;
        margin: 20px 20px;
    }
}